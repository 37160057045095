import React, { useEffect, useRef, useState } from "react";
import Style from "./AddOnPackage.module.scss";
import HomeChooseAPlan from "../HomeChooseAPlan";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useHome } from "../../Logic/useHome";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import { Swiper, SwiperSlide } from "swiper/react";
import useAuthChecker from "../../Logic/useAuthChecker";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import parse from "html-react-parser";

import loader from "../../assets/images/loader/Spinner.svg";

import {
  paymentModalAction,
  updateConfig,
} from "../../store/slices/checkoutSlice";
import { useTranslation } from "react-i18next";
import { authModalHandler, changeScreen } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import CheckOutComponent from "../Payment/CheckOutComponent";
import { useSelector } from "react-redux";
import CommonModal from "../Modals/commonModal";
import ThankYouModal from "../ThankYouModal";
import { toast } from "react-toastify";
const Addon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plans, plansData, AddOnData, AddOnPlans,AddOnStatus} = useHome();
  const { isAuthCompleted } = useAuthChecker();
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);
  const swiperRef = useRef(null);
  const { t } = useTranslation(["common"]);
  const { isOpen, data, thankYouPopup } = useSelector((state) => state.payment);
  const { planData, isPlanActive } = usePurchasedChecker();
  const {
    handleInitialtePayment,
    status,
    selectedId,
    country,
    closePaymentModal,
  } = usePlans();
  const handleMouseEnter = () => {
    if (swiperRef.current && autoplayEnabled) {
      swiperRef.current.autoplay.stop();
      setAutoplayEnabled(false);
    }
  };
  useEffect(() => {
    AddOnPlans();
  }, []);

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    if (swiperRef.current && !autoplayEnabled) {
      swiperRef.current.autoplay.start();
      setAutoplayEnabled(true);
    }
  };
  const handleClickLogin = () => {
    dispatch(authModalHandler(true));
    dispatch(changeScreen("login"));
  };
  const handleSubscribe = (item) => {
    if (item?.plans_prices?.[0]?.countries?.country_dial_code == "91") {
      let data = {
        isOpen: false,
        item: {},
        type: "Addon",
      };
      dispatch(paymentModalAction(data));
      window.location = `/payment/${item.id}/${item?.plans_prices[0]?.countries?.id}/${item?.price}/Addon`;
    } else if (isAuthCompleted) {
      if(item?.plans_prices?.[0]?.countries?.id &&item?.plans_prices?.[0]?.countries?.currency_code){
        let data = {
          isOpen: true,
          item: item,
          type: "Addon",
        };
        dispatch(paymentModalAction(data));
      }else{
      toast.error("This plan is not available in your country.");
      }
      //  handleInitialtePayment(item);
    } else {
      handleClickLogin();
    }
  };
  const handleGotoLogin = () => {
    handleClickLogin();
  };
  const handleCloseThankYOu = () => {
    dispatch(updateConfig((state) => (state.thankYouPopup = true)));
  };
  return (
    <>
      {(AddOnData?.length > 0 && AddOnStatus==="succeed") ? (
        <div className={Style.home_choose_plans}>
          <div>
            <div className={Style.my_gallery_header_wrap}>            
            <h2>Add On Package</h2>
            </div>

            <>
              <div className={Style.user_profile_members}></div>
            </>
            <div
            // className={`col-12 col-lg-6 col-xl-5 ${
            //   isPlanActive ? Style.purchased_comments : ""
            // }`}
            >
              {isPlanActive || isAuthCompleted ? (
                <div
                  className={`${Style.home_choose_plans_cards} ${Style.singleOffer}`}
                >
                  {AddOnData?.length == 1 || AddOnData?.length == 2 ? (
                    <>
                      {AddOnData?.map((item) => (
                        <div
                          className={`${Style.home_choose_plans_card_wrap}`}
                          key={item.id}
                        >
                          <Link to="">
                            <div className={Style.home_choose_plans_card}>
                              <div className={Style.card_top}>
                                <div
                                  className={`${Style.plan_duration} ${Style.plan_monthly}`}
                                >
                                  {item.name}
                                  {item.is_recommended ? (
                                    <span className={Style.recommended_label}>
                                      Recommended
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h4 className={Style.plan_amount}>
                                  <span className={Style.plan_amount_value}>
                                    {item?.plans_prices?.[0]?.symbol}
                                    {item?.price}
                                  </span>
                                </h4>
                                <p className={Style.plan_description}>
                                  {item.description}
                                </p>
                                <div className={Style.plan_features}>
                                  <>
                                    {item?.features?.length > 0
                                      ? parse(item?.features)
                                      : ""}
                                  </>
                                </div>
                              </div>
                              <div
                                className={Style.home_choose_plans_card_button}
                              >
                                <button
                                  className="btn btn-border-primary"
                                  onClick={() =>
                                    isAuthCompleted
                                      ? handleSubscribe(item)
                                      : handleGotoLogin()
                                  }
                                >
                                  {selectedId == item?.id &&
                                  status == "loading" ? (
                                    <InfiniteDotLoader />
                                  ) : (
                                    <>{t("subscribe")}</>
                                  )}
                                </button>

                                {/* {!isPlanActive ? (
                                    <button
                                      className="btn btn-border-primary"
                                      onClick={() => handleSubscribe(item)}
                                    >
                                      {selectedId == item?.id &&
                                      status == "loading" ? (
                                        <InfiniteDotLoader />
                                      ) : (
                                        <>{t("subscribe")}</>
                                      )}
                                    </button>
                                  ) : isAuthCompleted && isPlanActive ? (
                                    <button className="btn btn-border-primary">
                                      {t("already_purchased")}🎉
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-border-primary"
                                      onClick={() => handleGotoLogin()}
                                    >
                                      {t("subscribe")}
                                    </button>
                                  )} */}
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <Swiper
                        ref={swiperRef}
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        loop={true}
                        navigation={{
                          prevEl: ".swiper-navigation-prev",
                          nextEl: ".swiper-navigation-next",
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                          },
                          1200: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                          },
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        autoplay={{
                          delay: 21500,
                          disableOnInteraction: false,
                        }}
                        speed={1200}
                        className={`${Style.home_choose_plans_swiper}`}
                        pauseOnMouseEnter={true}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {AddOnData?.length > 0
                          ? AddOnData?.map((item, index) => {
                              return (
                                <SwiperSlide
                                  className={`${Style.home_choose_plans_card_wrap}`}
                                  key={item.id}
                                >
                                  <Link to="">
                                    <div
                                      className={Style.home_choose_plans_card}
                                    >
                                      <div className={Style.card_top}>
                                        <div
                                          className={`${Style.plan_duration} ${Style.plan_monthly}`}
                                        >
                                          {item?.name}
                                          {item?.is_recommended ? (
                                            <span
                                              className={
                                                Style.recommended_label
                                              }
                                            >
                                              Recommended
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <h4 className={Style.plan_amount}>
                                          <span
                                            className={Style.plan_amount_value}
                                          >
                                            {item?.plans_prices?.[0]?.symbol}
                                            {item.price}
                                          </span>
                                        </h4>
                                        <p className={Style.plan_description}>
                                          {item.description}
                                        </p>
                                        <div className={Style.plan_features}>
                                          <>
                                            {item?.features?.length > 0
                                              ? parse(item?.features)
                                              : ""}
                                          </>
                                        </div>
                                      </div>
                                      <button
                                        className="btn btn-border-primary"
                                        onClick={() =>
                                          isAuthCompleted
                                            ? handleSubscribe(item)
                                            : handleGotoLogin()
                                        }
                                      >
                                        {selectedId == item?.id &&
                                        status == "loading" ? (
                                          <InfiniteDotLoader />
                                        ) : (
                                          <>{t("subscribe")}</>
                                        )}
                                      </button>
                                    </div>
                                  </Link>
                                </SwiperSlide>
                              );
                            })
                          : [1, 2].map((item, index) => {
                              return (
                                // <SwiperSlide>
                                <div
                                  className={`${Style.home_choose_plans_card_wrap} data-loading`}
                                  key={index}
                                >
                                  <div className={Style.home_choose_plans_card}>
                                    <div className={Style.card_top}>
                                      <div
                                        className={`${Style.plan_duration} ${Style.plan_monthly}`}
                                      ></div>
                                      <h4 className={Style.plan_amount}>
                                        <span
                                          className={Style.plan_amount_value}
                                        ></span>{" "}
                                      </h4>
                                      <p className={Style.plan_description}>
                                        <span className={Style.lines}></span>
                                        <span className={Style.lines}></span>
                                        <span className={Style.lines}></span>
                                      </p>
                                      <ul className={Style.plan_features}>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                      </ul>
                                    </div>
                                    <div
                                      className={
                                        Style.home_choose_plans_card_button
                                      }
                                    ></div>
                                  </div>
                                </div>
                                // </SwiperSlide>
                              );
                            })}
                      </Swiper>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : AddOnStatus==="loading" && (
        <div className={`${Style.spinnerWrap} w-100 h-100 d-flex`}>
          <img src={loader} className={`m-auto ${Style.spinner}`} alt="" />
        </div>
      )}
      <CommonModal
        showModal={isOpen}
        hide={closePaymentModal}
        className={Style.payment_modal}
        content={<CheckOutComponent AddOnData={AddOnData} />}
      />
      <CommonModal
        showModal={thankYouPopup}
        hide={handleCloseThankYOu}
        className={`${Style.success_modal}`}
        content={
          <ThankYouModal
            successMessage={
              "Your payment was successful, and the add-on has been added to your package. Enjoy the new features!"
            }
          />
        }
      />
    </>
  );
};

export default Addon;
